import { Button, Typography } from 'antd';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import EmptyLayout from '../../components/layout/EmptyLayout';
import SearchEngineOptimization from '../../components/utility/seo';

const { Text, Title } = Typography;

const ApprovalRequired = () => {
  return (
    <EmptyLayout>
      <SearchEngineOptimization title="Approval Required" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../../images/x-circle.png"
          alt="x-circle"
          placeholder="tracedSVG"
          className="w-[48px] h-[48px]"
          objectFit="contain"
        />
        <Title className="!font-semibold mt-5 mb-4 !text-light-title" level={3}>
          ไม่มีสิทธิ์การเข้าถึง
        </Title>
        <Text className="!text-light-primary text-center">
          บัญชีของคุณยังไม่ได้รับการยืนยันจากผู้ดูแลระบบ
        </Text>
        <Link to={`/`}>
          <Button type="primary" className="mt-6">
            กลับสู่หน้าแรก
          </Button>
        </Link>
      </div>
    </EmptyLayout>
  );
};

export default ApprovalRequired;
